

@import "_reset";
@import "_variables";
@import "_mixins";

/*
block-name
block-name_modifier_name
block-name__element-name
block-name__element-name_modifier_name
*/


::selection {
    color: $white;
    background-color: $orange;
}

.home-section-1 {
    position: relative;
    background-color: $dark-grey;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .home-section-1__topbar {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        display: flex;
        align-items: center;
        padding: 24px;

        @include for-phone-only {    
            justify-content: center;
        }
        @include for-tablet-portrait-up {    
            justify-content: center;
        }
        @include for-tablet-landscape-up {    
            justify-content: flex-end;
        }

        .home-section-1__topbar__wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include for-phone-only {    
                width: 80%;
            }
            @include for-tablet-portrait-up {    
                width: 100%;
            }
            @include for-tablet-landscape-up {    
                width: 30%;
            }
            @include for-desktop-up {
                width: 23%;
            }

            & > p {
                color: $white;
                font-family: 'Loved by the King', cursive;
                text-align: center;

                @include for-phone-only {    
                    font-size: 15px;
                }
                @include for-tablet-portrait-up {    
                    font-size: 28px;
                }
                @include for-tablet-landscape-up {    
                    font-size: 28px;
                }
            }
        }

        .home-section-1__topbar__icon {
            color: $white;
            border-radius: 50px;
            padding: 8px;
            border: 2px solid $orange;
            transition: border 0.15s ease-in;

            &:hover {
                border: 2px solid $white;
            }
        }
    }

    .home-section-1__bottombar {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding-bottom: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > p {
            color: $white;
            font-family: 'Loved by the King', cursive;
            font-size: 28px;
            text-align: center;
        }

        @-webkit-keyframes fadein {
            from { opacity: 0; }
            to { opacity: 1; }
        }
        
        @-moz-keyframes fadein {
            from { opacity: 0; }
            to { opacity: 1; }
        }
        
        @keyframes fadein {
            from { opacity: 0; }
            to { opacity: 1; }
        }

        & > img {
            -webkit-animation: fadein 1.25s ease-in alternate infinite;
            -moz-animation: fadein 1.25s ease-in alternate infinite;
            animation: fadein 1.25s ease-in alternate infinite;
        }
    }

    .home-section-1__firstname {
        color: $orange;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        padding-bottom: 8px;
    
        &::after {
            display: flex;
            content: '';
            border-bottom: solid 3px $white;  
            transform: scaleX(0);  
            transition: transform 250ms ease-in-out;
        }

        &:hover::after { 
            transform: scaleX(1); 
        }
        
        @include for-phone-only {    
            font-size: 60px;
        }
        @include for-tablet-portrait-up {    
            font-size: 80px;
        }
        @include for-tablet-landscape-up {    
            font-size: 108px;
        }
    }

    .home-section-1__lastname {
        color: $white;
        font-family: 'Lato', sans-serif;
        font-weight: 900;

        @include for-phone-only {    
            font-size: 80px;
        }
        @include for-tablet-portrait-up {    
            font-size: 100px;
        }
        @include for-tablet-landscape-up {    
            font-size: 144px;
        }
    }

    .home-section-1__job {
        color: $white;
        font-family: 'Loved by the King', cursive;
        font-weight: 400;
        text-align: center;
        letter-spacing: 8px;

        @include for-phone-only {    
            font-size: 16px;
        }
        @include for-tablet-portrait-up {    
            font-size: 18px;
        }
        @include for-tablet-landscape-up {    
            font-size: 28px;
        }
    }
}

.home-section-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include for-phone-only {    
        height: auto;
        padding: 64px 32px;
    }
    @include for-tablet-portrait-up {    
        height: auto;
        padding: 64px 32px;
    }
    @include for-tablet-landscape-up {    
        height: 80vh;
        padding: 128px;
    }

    .home-section-2__col-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 50%;

        @include for-phone-only {    
            width: 100%;
        }
        @include for-tablet-portrait-up {    
            width: 100%;
        }
        @include for-tablet-landscape-up {    
            width: 50%;
        }

        & > p:nth-child(1){
            color: $orange;
            font-family: 'Lato', sans-serif;
            font-weight: 900;
            text-transform: uppercase;

            @include for-phone-only {    
                font-size: 60px;
            }
            @include for-tablet-portrait-up {    
                font-size: 80px;
            }
            @include for-tablet-landscape-up {    
                font-size: 113px;
            }
        }

        & > p:nth-child(2){
            color: $dark-grey;
            font-family: 'Lato', sans-serif;
            font-weight: 900;
            
            @include for-phone-only {    
                font-size: 24px;
            }
            @include for-tablet-portrait-up {    
                font-size: 32px;
            }
            @include for-tablet-landscape-up {    
                font-size: 48px;
            }
        }

        & > p:nth-child(3){
            color: $dark-grey;
            font-family: 'Lato', sans-serif;
            font-weight: 300;
            
            @include for-phone-only {    
                font-size: 14px;
                padding: 8px 0px;
            }
            @include for-tablet-portrait-up {    
                font-size: 15px;
            }
            @include for-tablet-landscape-up {    
                font-size: 16px;
            }
        }

        & > p:nth-child(4){
            color: $orange;
            font-family: 'Loved by the King', cursive;
            font-weight: 400;
            
            @include for-phone-only {    
                font-size: 48px;
            }
            @include for-tablet-portrait-up {    
                font-size: 52px;
            }
            @include for-tablet-landscape-up {    
                font-size: 65px;
            }
        }

        & > p:nth-child(5), & > p:nth-child(6) {
            color: $dark-grey;
            font-family: 'Loved by the King', cursive;
            font-weight: 400;
            
            @include for-phone-only {    
                font-size: 32px;
            }
            @include for-tablet-portrait-up {    
                font-size: 38px;
            }
            @include for-tablet-landscape-up {    
                font-size: 40px;
            }

            & span {
                border-bottom: solid 4px $orange;
            }
        }
    }

    .home-section-2__col-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        @include for-phone-only {    
            width: 100%;
            padding: 24px;
        }
        @include for-tablet-portrait-up {    
            width: 100%;
            padding: 24px;
        }
        @include for-tablet-landscape-up {    
            width: 50%;
        }

        & > figure {
            background: url(./../img/me.jpg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            mix-blend-mode: normal;
            position: relative;
            overflow: hidden;

            @include for-phone-only {    
                height: 80vw;
                width: 80vw;
                border-radius: 50%;
            }
            @include for-tablet-portrait-up {    
                height: 80vw;
                width: 80vw;
                border-radius: 50%;
            }
            @include for-tablet-landscape-up {    
                height: 450px;
                width: 450px;
                border-radius: 20px;
            }

            & > img {
                position: absolute;
                bottom: -100px;
                box-shadow: #000 0 10px 10px;
            }
        }
    }
}

.home-section-3 {
    background-color: $light-grey;

    @include for-phone-only {    
        padding: 64px 32px;
    }
    @include for-tablet-portrait-up {    
        padding: 64px 32px;
    }
    @include for-tablet-landscape-up {    
        padding: 128px;
    }

    & .home-section-3__row {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    & .home-section-3__row__col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include for-phone-only {    
            width: 100%;
        }
        @include for-tablet-portrait-up {    
            width: 100%;
        }
        @include for-tablet-landscape-up {    
            width: 50%;
            padding: 32px;
        }

        & .home-section-3__row__col__title {
            color: $orange;
            font-family: 'Loved by the King', cursive;
            font-weight: 400;
            font-size: 35px;
            text-align: center;
            
            @include for-phone-only {    
                padding: 32px 0px;
            }
            @include for-tablet-portrait-up {    
                padding: 24px 0px;
            }
            @include for-tablet-landscape-up {    
                padding: 12px 0px;
            }
        }

        & .home-section-3__row__col__grid {
            @include for-phone-only {    
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 16px;
                grid-row-gap: 16px;
            }
            @include for-tablet-portrait-up {    
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 16px;
                grid-row-gap: 16px;
            }
            @include for-tablet-landscape-up {    
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                flex-wrap: wrap;
            }

            & > .home-section-3__row__col__grid__item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                padding: 0 8px;

                & > img {
                    width: 64px;
                    height: auto;
                    fill: $orange;
                }

                & > p {
                    color: $dark-grey;
                    font-family: 'Lato', sans-serif;
                    font-weight: 300;
                    font-size: 15px;
                    text-transform: uppercase;
                    text-align: center;
                    padding-top: 8px;
                    transition: color 0.2s ease-in;
                }

                &:hover > p {
                    color: $orange;
                }
            }
        }

        
    }
}

.home-footer {
    background: url('/src/img/footer-img.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & .home-footer__cto {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba($color: $dark-grey, $alpha: 0.9);
        width: 100%;
        height: 100%;

        & > img {
            width: 100%;
            object-fit: cover;
            opacity: 0.1;
        }
        
        & > p {
            color: $white;
            font-family: 'Loved by the King', cursive;
            font-size: 72px;
            text-align: center;

            @include for-phone-only {    
                font-size: 54px;
            }
            @include for-tablet-portrait-up {    
                font-size: 64px;
            }
            @include for-tablet-landscape-up {    
                font-size: 72px;
            }

            & > span {
                color: $orange;
                font-family: 'Loved by the King', cursive;
                
                @include for-phone-only {    
                    font-size: 54px;
                }
                @include for-tablet-portrait-up {    
                    font-size: 64px;
                }
                @include for-tablet-landscape-up {    
                    font-size: 72px;
                }
            }
        }

        & i {
            color: $white;
            border-radius: 50px;
            padding: 16px;
            margin: 0px 8px;
            border: 2px solid $orange;
            transition: border 0.15s ease-in;
            font-size: 2em;

            &:hover {
                border: 2px solid $white;
            }
        }
    }

    & .home-footer__bottombar {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        & p {
            color: $white; 
            font-family: 'Lato', sans-serif;
            font-weight: 300;
            font-size: 12px;
            padding: 8px 0px;

            &::after {
                display: flex;
                content: '';
                border-bottom: solid 3px $white;  
                transform: scaleX(0);  
                transition: transform 250ms ease-in-out;
            }
    
            &:hover::after { 
                transform: scaleX(1); 
            }
        }

    }
}