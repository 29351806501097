@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Loved+by+the+King&display=swap");
html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}
ul {
  list-style: none;
}
button, input, select {
  margin: 0;
}
html {
  box-sizing: border-box;
}
*, *::before, *::after {
  box-sizing: inherit;
}
img, video {
  height: auto;
  max-width: 100%;
}
iframe {
  border: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td, th {
  padding: 0;
}
/*
block-name
block-name_modifier_name
block-name__element-name
block-name__element-name_modifier_name
*/
::selection {
  color: white;
  background-color: #FB8122;
}
.home-section-1 {
  position: relative;
  background-color: #1D2228;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-section-1 .home-section-1__topbar {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  padding: 24px;
}
@media (max-width: 599px) {
  .home-section-1 .home-section-1__topbar {
    justify-content: center;
  }
}
@media (min-width: 600px) {
  .home-section-1 .home-section-1__topbar {
    justify-content: center;
  }
}
@media (min-width: 900px) {
  .home-section-1 .home-section-1__topbar {
    justify-content: flex-end;
  }
}
.home-section-1 .home-section-1__topbar .home-section-1__topbar__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 599px) {
  .home-section-1 .home-section-1__topbar .home-section-1__topbar__wrapper {
    width: 80%;
  }
}
@media (min-width: 600px) {
  .home-section-1 .home-section-1__topbar .home-section-1__topbar__wrapper {
    width: 100%;
  }
}
@media (min-width: 900px) {
  .home-section-1 .home-section-1__topbar .home-section-1__topbar__wrapper {
    width: 30%;
  }
}
@media (min-width: 1200px) {
  .home-section-1 .home-section-1__topbar .home-section-1__topbar__wrapper {
    width: 23%;
  }
}
.home-section-1 .home-section-1__topbar .home-section-1__topbar__wrapper > p {
  color: white;
  font-family: "Loved by the King", cursive;
  text-align: center;
}
@media (max-width: 599px) {
  .home-section-1 .home-section-1__topbar .home-section-1__topbar__wrapper > p {
    font-size: 15px;
  }
}
@media (min-width: 600px) {
  .home-section-1 .home-section-1__topbar .home-section-1__topbar__wrapper > p {
    font-size: 28px;
  }
}
@media (min-width: 900px) {
  .home-section-1 .home-section-1__topbar .home-section-1__topbar__wrapper > p {
    font-size: 28px;
  }
}
.home-section-1 .home-section-1__topbar .home-section-1__topbar__icon {
  color: white;
  border-radius: 50px;
  padding: 8px;
  border: 2px solid #FB8122;
  transition: border 0.15s ease-in;
}
.home-section-1 .home-section-1__topbar .home-section-1__topbar__icon:hover {
  border: 2px solid white;
}
.home-section-1 .home-section-1__bottombar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-section-1 .home-section-1__bottombar > p {
  color: white;
  font-family: "Loved by the King", cursive;
  font-size: 28px;
  text-align: center;
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.home-section-1 .home-section-1__bottombar > img {
  -webkit-animation: fadein 1.25s ease-in alternate infinite;
  -moz-animation: fadein 1.25s ease-in alternate infinite;
  animation: fadein 1.25s ease-in alternate infinite;
}
.home-section-1 .home-section-1__firstname {
  color: #FB8122;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  padding-bottom: 8px;
}
.home-section-1 .home-section-1__firstname::after {
  display: flex;
  content: "";
  border-bottom: solid 3px white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.home-section-1 .home-section-1__firstname:hover::after {
  transform: scaleX(1);
}
@media (max-width: 599px) {
  .home-section-1 .home-section-1__firstname {
    font-size: 60px;
  }
}
@media (min-width: 600px) {
  .home-section-1 .home-section-1__firstname {
    font-size: 80px;
  }
}
@media (min-width: 900px) {
  .home-section-1 .home-section-1__firstname {
    font-size: 108px;
  }
}
.home-section-1 .home-section-1__lastname {
  color: white;
  font-family: "Lato", sans-serif;
  font-weight: 900;
}
@media (max-width: 599px) {
  .home-section-1 .home-section-1__lastname {
    font-size: 80px;
  }
}
@media (min-width: 600px) {
  .home-section-1 .home-section-1__lastname {
    font-size: 100px;
  }
}
@media (min-width: 900px) {
  .home-section-1 .home-section-1__lastname {
    font-size: 144px;
  }
}
.home-section-1 .home-section-1__job {
  color: white;
  font-family: "Loved by the King", cursive;
  font-weight: 400;
  text-align: center;
  letter-spacing: 8px;
}
@media (max-width: 599px) {
  .home-section-1 .home-section-1__job {
    font-size: 16px;
  }
}
@media (min-width: 600px) {
  .home-section-1 .home-section-1__job {
    font-size: 18px;
  }
}
@media (min-width: 900px) {
  .home-section-1 .home-section-1__job {
    font-size: 28px;
  }
}
.home-section-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 599px) {
  .home-section-2 {
    height: auto;
    padding: 64px 32px;
  }
}
@media (min-width: 600px) {
  .home-section-2 {
    height: auto;
    padding: 64px 32px;
  }
}
@media (min-width: 900px) {
  .home-section-2 {
    height: 80vh;
    padding: 128px;
  }
}
.home-section-2 .home-section-2__col-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
}
@media (max-width: 599px) {
  .home-section-2 .home-section-2__col-1 {
    width: 100%;
  }
}
@media (min-width: 600px) {
  .home-section-2 .home-section-2__col-1 {
    width: 100%;
  }
}
@media (min-width: 900px) {
  .home-section-2 .home-section-2__col-1 {
    width: 50%;
  }
}
.home-section-2 .home-section-2__col-1 > p:nth-child(1) {
  color: #FB8122;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}
@media (max-width: 599px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(1) {
    font-size: 60px;
  }
}
@media (min-width: 600px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(1) {
    font-size: 80px;
  }
}
@media (min-width: 900px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(1) {
    font-size: 113px;
  }
}
.home-section-2 .home-section-2__col-1 > p:nth-child(2) {
  color: #1D2228;
  font-family: "Lato", sans-serif;
  font-weight: 900;
}
@media (max-width: 599px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(2) {
    font-size: 24px;
  }
}
@media (min-width: 600px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(2) {
    font-size: 32px;
  }
}
@media (min-width: 900px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(2) {
    font-size: 48px;
  }
}
.home-section-2 .home-section-2__col-1 > p:nth-child(3) {
  color: #1D2228;
  font-family: "Lato", sans-serif;
  font-weight: 300;
}
@media (max-width: 599px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(3) {
    font-size: 14px;
    padding: 8px 0px;
  }
}
@media (min-width: 600px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(3) {
    font-size: 15px;
  }
}
@media (min-width: 900px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(3) {
    font-size: 16px;
  }
}
.home-section-2 .home-section-2__col-1 > p:nth-child(4) {
  color: #FB8122;
  font-family: "Loved by the King", cursive;
  font-weight: 400;
}
@media (max-width: 599px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(4) {
    font-size: 48px;
  }
}
@media (min-width: 600px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(4) {
    font-size: 52px;
  }
}
@media (min-width: 900px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(4) {
    font-size: 65px;
  }
}
.home-section-2 .home-section-2__col-1 > p:nth-child(5), .home-section-2 .home-section-2__col-1 > p:nth-child(6) {
  color: #1D2228;
  font-family: "Loved by the King", cursive;
  font-weight: 400;
}
@media (max-width: 599px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(5), .home-section-2 .home-section-2__col-1 > p:nth-child(6) {
    font-size: 32px;
  }
}
@media (min-width: 600px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(5), .home-section-2 .home-section-2__col-1 > p:nth-child(6) {
    font-size: 38px;
  }
}
@media (min-width: 900px) {
  .home-section-2 .home-section-2__col-1 > p:nth-child(5), .home-section-2 .home-section-2__col-1 > p:nth-child(6) {
    font-size: 40px;
  }
}
.home-section-2 .home-section-2__col-1 > p:nth-child(5) span, .home-section-2 .home-section-2__col-1 > p:nth-child(6) span {
  border-bottom: solid 4px #FB8122;
}
.home-section-2 .home-section-2__col-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
@media (max-width: 599px) {
  .home-section-2 .home-section-2__col-2 {
    width: 100%;
    padding: 24px;
  }
}
@media (min-width: 600px) {
  .home-section-2 .home-section-2__col-2 {
    width: 100%;
    padding: 24px;
  }
}
@media (min-width: 900px) {
  .home-section-2 .home-section-2__col-2 {
    width: 50%;
  }
}
.home-section-2 .home-section-2__col-2 > figure {
  background: url(me.21beed90.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: normal;
  position: relative;
  overflow: hidden;
}
@media (max-width: 599px) {
  .home-section-2 .home-section-2__col-2 > figure {
    height: 80vw;
    width: 80vw;
    border-radius: 50%;
  }
}
@media (min-width: 600px) {
  .home-section-2 .home-section-2__col-2 > figure {
    height: 80vw;
    width: 80vw;
    border-radius: 50%;
  }
}
@media (min-width: 900px) {
  .home-section-2 .home-section-2__col-2 > figure {
    height: 450px;
    width: 450px;
    border-radius: 20px;
  }
}
.home-section-2 .home-section-2__col-2 > figure > img {
  position: absolute;
  bottom: -100px;
  box-shadow: #000 0 10px 10px;
}
.home-section-3 {
  background-color: #f5f5f5;
}
@media (max-width: 599px) {
  .home-section-3 {
    padding: 64px 32px;
  }
}
@media (min-width: 600px) {
  .home-section-3 {
    padding: 64px 32px;
  }
}
@media (min-width: 900px) {
  .home-section-3 {
    padding: 128px;
  }
}
.home-section-3 .home-section-3__row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.home-section-3 .home-section-3__row__col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 599px) {
  .home-section-3 .home-section-3__row__col {
    width: 100%;
  }
}
@media (min-width: 600px) {
  .home-section-3 .home-section-3__row__col {
    width: 100%;
  }
}
@media (min-width: 900px) {
  .home-section-3 .home-section-3__row__col {
    width: 50%;
    padding: 32px;
  }
}
.home-section-3 .home-section-3__row__col .home-section-3__row__col__title {
  color: #FB8122;
  font-family: "Loved by the King", cursive;
  font-weight: 400;
  font-size: 35px;
  text-align: center;
}
@media (max-width: 599px) {
  .home-section-3 .home-section-3__row__col .home-section-3__row__col__title {
    padding: 32px 0px;
  }
}
@media (min-width: 600px) {
  .home-section-3 .home-section-3__row__col .home-section-3__row__col__title {
    padding: 24px 0px;
  }
}
@media (min-width: 900px) {
  .home-section-3 .home-section-3__row__col .home-section-3__row__col__title {
    padding: 12px 0px;
  }
}
@media (max-width: 599px) {
  .home-section-3 .home-section-3__row__col .home-section-3__row__col__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
}
@media (min-width: 600px) {
  .home-section-3 .home-section-3__row__col .home-section-3__row__col__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
}
@media (min-width: 900px) {
  .home-section-3 .home-section-3__row__col .home-section-3__row__col__grid {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}
.home-section-3 .home-section-3__row__col .home-section-3__row__col__grid > .home-section-3__row__col__grid__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
}
.home-section-3 .home-section-3__row__col .home-section-3__row__col__grid > .home-section-3__row__col__grid__item > img {
  width: 64px;
  height: auto;
  fill: #FB8122;
}
.home-section-3 .home-section-3__row__col .home-section-3__row__col__grid > .home-section-3__row__col__grid__item > p {
  color: #1D2228;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 8px;
  transition: color 0.2s ease-in;
}
.home-section-3 .home-section-3__row__col .home-section-3__row__col__grid > .home-section-3__row__col__grid__item:hover > p {
  color: #FB8122;
}
.home-footer {
  background: url("footer-img.d89ec1eb.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.home-footer .home-footer__cto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(29, 34, 40, 0.9);
  width: 100%;
  height: 100%;
}
.home-footer .home-footer__cto > img {
  width: 100%;
  object-fit: cover;
  opacity: 0.1;
}
.home-footer .home-footer__cto > p {
  color: white;
  font-family: "Loved by the King", cursive;
  font-size: 72px;
  text-align: center;
}
@media (max-width: 599px) {
  .home-footer .home-footer__cto > p {
    font-size: 54px;
  }
}
@media (min-width: 600px) {
  .home-footer .home-footer__cto > p {
    font-size: 64px;
  }
}
@media (min-width: 900px) {
  .home-footer .home-footer__cto > p {
    font-size: 72px;
  }
}
.home-footer .home-footer__cto > p > span {
  color: #FB8122;
  font-family: "Loved by the King", cursive;
}
@media (max-width: 599px) {
  .home-footer .home-footer__cto > p > span {
    font-size: 54px;
  }
}
@media (min-width: 600px) {
  .home-footer .home-footer__cto > p > span {
    font-size: 64px;
  }
}
@media (min-width: 900px) {
  .home-footer .home-footer__cto > p > span {
    font-size: 72px;
  }
}
.home-footer .home-footer__cto i {
  color: white;
  border-radius: 50px;
  padding: 16px;
  margin: 0px 8px;
  border: 2px solid #FB8122;
  transition: border 0.15s ease-in;
  font-size: 2em;
}
.home-footer .home-footer__cto i:hover {
  border: 2px solid white;
}
.home-footer .home-footer__bottombar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-footer .home-footer__bottombar p {
  color: white;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 12px;
  padding: 8px 0px;
}
.home-footer .home-footer__bottombar p::after {
  display: flex;
  content: "";
  border-bottom: solid 3px white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.home-footer .home-footer__bottombar p:hover::after {
  transform: scaleX(1);
}
/*# sourceMappingURL=index.5d22994d.css.map */
